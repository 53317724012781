import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import InkbleedSvg from "./inkbleed.svg";
import css from "./index.module.scss";
import hideModal from "./hideModal";
import MorphSVGPlugin from "scripts/gsap/MorphSVGPlugin";
import gsap from "scripts/gsap";
import AllChallengesSvg from "./allChallenges.svg";


export default forwardRef(({
  className = "",
  progress = 0,
  errorMessage = "",
}, ref) => {
  const modalWrapper = useRef();
  const [isOpen, setIsOpen] = useState(true);
  const [alreadyOpened, setAlreadyOpened] = useState(true);
  const progressBar = useRef();
  const loading = useRef();

  useImperativeHandle(ref, () => ({
    hide() {
      hideModal({css, isOpen, setIsOpen, modalWrapper});
    },
  }));

  useEffect(() => {
    let tl = gsap.to(progressBar.current, {
      scaleX: progress / 18,
      duration: 0.25,
      ease: "sine.inOut",
    });

    return () => {
      tl.kill();
    };
  }, [progress]);

  //animate the challenge icons
  /*
  useEffect(() => {
    gsap.registerPlugin(MorphSVGPlugin);

    let tl = gsap.timeline({repeat: -1});
    tl.set(`.${css.iconWrapper}`, {visibility: "visible"});

    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon9`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon7`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon2`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon3`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon6`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon5`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon1`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon4`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon8`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");
    tl.to(`.${css.iconWrapper} svg .icon0`, {morphSVG: {shape: `.${css.iconWrapper} svg .icon0`, type: "rotational"}, duration: 2, ease: "power1.inOut"}, ">0.25");

    return () => {
      tl.kill();
    };
  }, []);
  */

  return (
    <div className={`${className} ${css.modalWrapper}`} ref={modalWrapper}>
      <InkbleedSvg />
      <div className={css.modal}>
        {/*<div className={css.challengeIcons}>
          <div className={`initialInvis ${css.iconWrapper}`}>
            <AllChallengesSvg/>
          </div>
        </div>*/}
        <div className={css.modalInner}>
          <div className={css.loading} ref={loading}>
            <div className={css.headline}>Loading infinite art</div>

            <div className={css.loadingBar}>
              <div className={css.coloredBg} ref={progressBar}></div>
            </div>
            {/*<p><em>Click glowing objects, win prizes!</em></p>*/}
            <div className={css.errorMessage}>{errorMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
