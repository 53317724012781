import Head from "next/head";
import React, { useEffect, useRef, useState } from "react";

import Header from "sections/header/index.jsx";
import Footer from "sections/footer";
import * as PIXI from "pixi.js-legacy";
import { ExpoScaleEase } from "scripts/gsap/EasePack";
import PixiPlugin from "scripts/gsap/PixiPlugin";
import gsap from "scripts/gsap/index.js";
import {Assets} from "@pixi/assets";
import css from "./index.module.scss";
import Button from "/components/button/index.jsx";
import Modal from "/components/modal/index.jsx";
import MorphSVGPlugin from "scripts/gsap/MorphSVGPlugin";
import SubliminalSvg from "/public/images/icons/challenges/subliminal.svg";
import LucentSvg from "/public/images/icons/challenges/lucent.svg";
import RefrainSvg from "/public/images/icons/challenges/refrain.svg";
import KinestheticSvg from "/public/images/icons/challenges/kinesthetic.svg";
import SenseSvg from "/public/images/icons/challenges/sense.svg";
import MonikerSvg from "/public/images/icons/challenges/moniker.svg";
import MinutiaeSvg from "/public/images/icons/challenges/minutiae.svg";
import DecorumSvg from "/public/images/icons/challenges/decorum.svg";
import GravenSvg from "/public/images/icons/challenges/graven.svg";
import SilenceSvg from "/public/images/icons/challenges/silence.svg";
import { useUser } from "scripts/apis/x/useUser";
import strapi from "/scripts/apis/strapi/index.js";
import mpTag from "scripts/tag/mpTag";
import LoadingScreen from "@/components/loadingScreen";
import InfoSvg from "/public/images/icons/info.svg";
import Link from "next/link";
import RegisterForm from "forms/auth/register";
import FloatingBar from "@/components/floatingBar";

export default function Home() {
  const pixiView = useRef();
  const description = "X Marks The Site";
  const title = "X Marks The Site";
  const modal = useRef();
  const user = useUser({refreshInterval: 10000});
  const [activeChallenge, setActiveChallenge] = useState("");
  const [zoomTl, setZoomTl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const loadingScreen = useRef();


  const challengeIcons = {
    subliminal: <SubliminalSvg/>,
    lucent: <LucentSvg/>,
    refrain: <RefrainSvg/>,
    kinesthetic: <KinestheticSvg/>,
    sense: <SenseSvg/>,
    moniker: <MonikerSvg/>,
    minutiae: <MinutiaeSvg/>,
    decorum: <DecorumSvg/>,
    graven: <GravenSvg/>,
    silence: <SilenceSvg/>,
  };

  useEffect(() => {
    async function effect(){
      gsap.registerPlugin(PixiPlugin);
      gsap.registerPlugin(ExpoScaleEase);
      gsap.registerPlugin(MorphSVGPlugin);

      let master = gsap.timeline({paused: true});
      let size = 0;
      if (window.screen.width > 1500 || window.screen.height > 1500){
        size = 2000;
      } else if (window.screen.width > 1000 || window.screen.height > 1000){
        size = 1500;
      } else if (window.screen.width > 500 || window.screen.height > 500){
        size = 1000;
      } else {
        size = 500;
      }

      PixiPlugin.registerPIXI(PIXI);
      PIXI.settings.SORTABLE_CHILDREN = true;
      let app = new PIXI.Application({backgroundColor: 0x000000, view: pixiView.current});

      let renderer = app.renderer;
      renderer.plugins.interaction.autoPreventDefault = false;
      renderer.view.style.touchAction = "auto";

      renderer.resize(size, size);

      let stage = app.stage;


      const frames = [
        {
          id: 1,
          interactives: [
            {x: 909 / 2000, y: 1082 / 2000, w: 182 / 2000, h: 21 / 2000, challenge: "subliminal", color: 0xffffff},
            {x: 717 / 2000, y: 529 / 2000, w: 108 / 2000, h: 57 / 2000, challenge: "moniker", color: 0xffffff},
            {x: 1619 / 2000, y: 842 / 2000, w: 99 / 2000, h: 132 / 2000, challenge: "graven", color: 0xffffff},
            {x: 994 / 2000, y: 942 / 2000, w: 11 / 2000, h: 13 / 2000, challenge: "silence", color: 0xffffff},
          ],
        },
        {
          id: 2,
          interactives: [
            {x: 801 / 2000, y: 1153 / 2000, w: 383 / 2000, h: 58 / 2000, challenge: "subliminal", color: 0xffffff},
            {x: 436 / 2000, y: 62 / 2000, w: 216 / 2000, h: 117 / 2000, challenge: "moniker", color: 0xffffff},
            {x: 990 / 2000, y: 880 / 2000, w: 18 / 2000, h: 31 / 2000, challenge: "silence", color: 0xffffff},
          ],
        },
        {
          id: 3,
          interactives: [
            {x: 587 / 2000, y: 1313 / 2000, w: 855 / 2000, h: 102 / 2000, challenge: "subliminal", color: 0xffffff},
            {x: 976 / 2000, y: 763 / 2000, w: 44 / 2000, h: 62 / 2000, challenge: "silence", color: 0xffffff},
          ],
        },

        {
          id: 4,
          interactives: [
            {x: 227 / 2000, y: 1616 / 2000, w: 1498 / 2000, h: 211 / 2000, challenge: "subliminal", color: 0xffffff},
            {x: 949 / 2000, y: 1075 / 2000, w: 9 / 2000, h: 13 / 2000, challenge: "lucent", color: 0xffffff},
            {x: 945 / 2000, y: 988 / 2000, w: 10 / 2000, h: 13 / 2000, challenge: "minutiae", color: 0xffffff},
            {x: 953 / 2000, y: 526 / 2000, w: 83 / 2000, h: 118 / 2000, challenge: "silence", color: 0xffffff},
          ],
        },
        {
          id: 5,
          interactives: [
            {x: 898 / 2000, y: 1149 / 2000, w: 18 / 2000, h: 30 / 2000, challenge: "lucent", color: 0xffffff},
            {x: 891 / 2000, y: 978 / 2000, w: 18 / 2000, h: 24 / 2000, challenge: "minutiae", color: 0xffffff},
            {x: 917 / 2000, y: 47 / 2000, w: 152 / 2000, h: 237 / 2000, challenge: "silence", color: 0xffffff},
          ],
        },
        {
          id: 6,
          interactives: [
            {x: 793 / 2000, y: 1296 / 2000, w: 35 / 2000, h: 62 / 2000, challenge: "lucent", color: 0xffffff},
            {x: 985 / 2000, y: 956 / 2000, w: 11 / 2000, h: 11 / 2000, challenge: "refrain", color: 0xffffff},
            {x: 1011 / 2000, y: 1032 / 2000, w: 8 / 2000, h: 9 / 2000, challenge: "sense", color: 0xffffff},
            {x: 783 / 2000, y: 958 / 2000, w: 33 / 2000, h: 43 / 2000, challenge: "minutiae", color: 0xffffff},
          ],
        },

        {
          id: 7,
          interactives: [
            {x: 604 / 2000, y: 1608 / 2000, w: 41 / 2000, h: 112 / 2000, challenge: "lucent", color: 0xffffff},
            {x: 970 / 2000, y: 910 / 2000, w: 24 / 2000, h: 24 / 2000, challenge: "refrain", color: 0xffffff},
            {x: 1024 / 2000, y: 1065 / 2000, w: 15 / 2000, h: 18 / 2000, challenge: "sense", color: 0xffffff},
            {x: 569 / 2000, y: 916 / 2000, w: 63 / 2000, h: 86 / 2000, challenge: "minutiae", color: 0xffffff},
          ],
        },
        {
          id: 8,
          interactives: [
            {x: 942 / 2000, y: 823 / 2000, w: 46 / 2000, h: 44 / 2000, challenge: "refrain", color: 0xffffff},
            {x: 843 / 2000, y: 879 / 2000, w: 31 / 2000, h: 25 / 2000, challenge: "kinesthetic", color: 0xffffff},
            {x: 1048 / 2000, y: 1130 / 2000, w: 29 / 2000, h: 36 / 2000, challenge: "sense", color: 0xffffff},
            {x: 139 / 2000, y: 830 / 2000, w: 122 / 2000, h: 169 / 2000, challenge: "minutiae", color: 0xffffff},
          ],
        },
        {
          id: 9,
          interactives: [
            {x: 885 / 2000, y: 646 / 2000, w: 91 / 2000, h: 87 / 2000, challenge: "refrain", color: 0xffffff},
            {x: 686 / 2000, y: 758 / 2000, w: 64 / 2000, h: 51 / 2000, challenge: "kinesthetic", color: 0xffffff},
            {x: 1099 / 2000, y: 1260 / 2000, w: 53 / 2000, h: 70 / 2000, challenge: "sense", color: 0xffffff},
          ],
        },
        {
          id: 10,
          interactives: [
            {x: 771 / 2000, y: 290 / 2000, w: 182 / 2000, h: 179 / 2000, challenge: "refrain", color: 0xffffff},
            {x: 373 / 2000, y: 517 / 2000, w: 125 / 2000, h: 101 / 2000, challenge: "kinesthetic", color: 0xffffff},
            {x: 1196 / 2000, y: 1522 / 2000, w: 106 / 2000, h: 137 / 2000, challenge: "sense", color: 0xffffff},
            {x: 1003 / 2000, y: 1013 / 2000, w: 10 / 2000, h: 13 / 2000, challenge: "decorum", color: 0xffffff},
          ],
        },
        {
          id: 11,
          interactives: [{x: 1006 / 2000, y: 1027 / 2000, w: 19 / 2000, h: 23 / 2000, challenge: "decorum", color: 0xffffff}],
        },
        {
          id: 12,
          interactives: [{x: 1013 / 2000, y: 1054 / 2000, w: 36 / 2000, h: 50 / 2000, challenge: "decorum", color: 0xffffff}],
        },
        {
          id: 13,
          interactives: [{x: 1026 / 2000, y: 1104 / 2000, w: 72 / 2000, h: 101 / 2000, challenge: "decorum", color: 0xffffff}],
        },
        {
          id: 14,
          interactives: [{x: 1075 / 2000, y: 1264 / 2000, w: 96 / 2000, h: 135 / 2000, challenge: "decorum", color: 0xffffff}],
        },
        {
          id: 15,
          interactives: [{x: 1149 / 2000, y: 1529 / 2000, w: 193 / 2000, h: 275 / 2000, challenge: "decorum", color: 0xffffff}],
        },
        {
          id: 16,
          interactives: [{x: 1038 / 2000, y: 990 / 2000, w: 8 / 2000, h: 9 / 2000, challenge: "graven", color: 0xffffff}],
        },
        {
          id: 17,
          interactives: [
            {x: 965 / 2000, y: 941 / 2000, w: 13 / 2000, h: 7 / 2000, challenge: "moniker", color: 0xffffff},
            {x: 1077 / 2000, y: 981 / 2000, w: 13 / 2000, h: 16 / 2000, challenge: "graven", color: 0xffffff},
          ],
        },
        {
          id: 18,
          interactives: [
            {x: 928 / 2000, y: 881 / 2000, w: 29 / 2000, h: 15 / 2000, challenge: "moniker", color: 0xffffff},
            {x: 1155 / 2000, y: 960 / 2000, w: 25 / 2000, h: 33 / 2000, challenge: "graven", color: 0xffffff},
          ],
        },
        {
          id: 19,
          interactives: [
            {x: 952 / 2000, y: 1042 / 2000, w: 99 / 2000, h: 17 / 2000, challenge: "subliminal", color: 0xffffff},
            {x: 857 / 2000, y: 765 / 2000, w: 56 / 2000, h: 28 / 2000, challenge: "moniker", color: 0xffffff},
            {x: 1309 / 2000, y: 929 / 2000, w: 50 / 2000, h: 68 / 2000, challenge: "graven", color: 0xffffff},
          ],
        },
      ];

      for (let i = 0; i < frames.length; i++){
        let texture = await Assets.load(`/images/zoomquilt/${frames[i].id}-${size}w.webp`).catch((err) => {
          console.error(err);
          setErrorMessage("Error loading infinite art. Please reload the page or check your internet connection.");
        });

        setProgress(i);
        let sprite = PIXI.Sprite.from(texture);
        sprite.width = size;
        sprite.height = size;
        sprite.x = size / 2;
        sprite.y = size / 2;
        sprite.pivot.set(size / 2, size / 2);
        sprite.scale.set(0, 0);
        if (i === 45){
          sprite.scale.set(1, 1);
        }

        sprite.zIndex = frames.length + i + 1;

        //sprite.filters = [new OutlineFilter(4, 0x99ff99)];

        stage.addChild(sprite);
        frames[i].sprite = sprite;

        /*disable interactivity
        for (let interactive of frames[i].interactives){
          let hitbox = new PIXI.Graphics();
          //set the line style to have a width of 5 and set the color to red
          hitbox.width = interactive.w * size;
          hitbox.height = interactive.h * size;
          hitbox.x = interactive.x * size;
          hitbox.y = interactive.y * size;

          hitbox.interactive = true;
          hitbox.hitArea = new PIXI.Rectangle(0, 0, interactive.w * size, interactive.h * size);
          hitbox.on("pointerdown", () => {
            modal.current.show().then(() => {
              master.pause();
            });

            //unlock the challenge if the user is logged in
            if (user?.value?.id && !(user.value?.challengesUnlocked || {})[interactive.challenge]){
              strapi.query(strapi.endpoints.USER_ME_PUT, {
                data: {
                  challengesUnlocked: {
                    [interactive.challenge]: true,
                  },
                },
              }).then(() => {
                user.mutate();
              });
            }

            setActiveChallenge(interactive.challenge);
            mpTag("challengeUnlocked", {name: interactive.challenge});
          });


          //draw a rectangle
          //hitbox.lineStyle(1, 0xFFFFFF);
          //hitbox.moveTo(0, 0);
          //hitbox.lineTo(interactive.w * size, 0);
          //hitbox.lineTo(interactive.w * size, interactive.h * size);
          //hitbox.lineTo(0, interactive.h * size);
          //hitbox.lineTo(0, 0);
          hitbox.zIndex = -1;
          frames[i].sprite.addChild(hitbox);

          //draw quest marker
          let questMarker = new PIXI.Graphics();
          questMarker.width = 48 * 2;
          questMarker.height = 48 * 2;
          questMarker.x = (interactive.x * size) + (interactive.w * size / 2);
          questMarker.y = (interactive.y * size) + (interactive.h * size / 2);

          let innerDot = new PIXI.Graphics();
          innerDot.beginFill(0xf0cf75);
          innerDot.drawCircle(48, 48, 12);
          innerDot.endFill();
          innerDot.pivot.x = 48;
          innerDot.pivot.y = 48;
          questMarker.addChild(innerDot);

          let pulsingRing = new PIXI.Graphics();
          pulsingRing.beginFill(0xf0cf75);
          pulsingRing.drawCircle(48, 48, 12);
          pulsingRing.endFill();
          pulsingRing.alpha = 0.5;
          pulsingRing.pivot.x = 48;
          pulsingRing.pivot.y = 48;
          questMarker.addChild(pulsingRing);

          let tl = gsap.timeline({repeat: -1});
          tl.fromTo(innerDot, {pixi: {scale: 1}}, {pixi: {scale: 0.8}, duration: 0.25, ease: "sine.inOut"});
          tl.to(innerDot, {pixi: {scale: 1}, duration: 0.125, ease: "sine.inOut"});
          tl.fromTo(pulsingRing, {pixi: {scale: 1, alpha: 0.75}}, {pixi: {scale: 4, alpha: 0}, duration: 2, ease: "sine.out"}, "<");
          tl.seek(Math.random(tl.duration()));
          frames[i].sprite.addChild(questMarker);
        }
        */
      }


      for (let i = frames.length - 1; i >= 0; i--){
        let tl = gsap.timeline({
          ease: "none",
          repeat: -1,
          delay: (frames.length - i - 2),
          repeatDelay: (frames.length) - 3,
          onRepeat: function(){
            frames[i].zIndex += frames.length;
          },
        });
        tl.set(frames[i].sprite, {pixi: {scale: 2, alpha: 1}});
        tl.to(frames[i].sprite, {
          pixi: {
            scale: 0.25,
          },
          ease: "expoScale(2, 0.25)",
          duration: 3,
        }, 0);
        tl.to(frames[i].sprite, {
          pixi: {
            alpha: 0,
          },
          duration: 0.5,
          ease: "sine.inOut",
        }, 2.5);

        master.add(tl, 0);
      }
      master.timeScale(0.15 * 1.8);
      //master.pause();
      setZoomTl(master);
      //master.seek(5);
      loadingScreen.current.hide();
      pixiView.current.scrollIntoView({block: "center"}); //center the zoomquilt

      master.play();

      gsap.fromTo(`.${css.infoTab}`, {
        translateX: "-100%",
        visibility: "visible",
      }, {
        translateX: 0,
        duration: 0.5,
        ease: "sine.inOut",
        delay: 5,
      });
    }
    effect();
  }, [modal, loadingScreen]);

  function continueSearching(){
    if (modal.current){
      modal.current.hide();
    }
    zoomTl.resume();
  }

  return (
    <div className="root">
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Head>
      <Header minimal/>
      <main className={css.main}>
        {/*disable challenge clicked modal
        <Modal ref={modal} onHide={continueSearching}>
          <div className={css.modalInner}>
            <div className={css.modalHeader}>Congratulations!</div>
            <div className={css.modalIcon}>
              {challengeIcons[activeChallenge]}
            </div>
            <div className={css.text}>You found the <b className={css.challengeName}>{activeChallenge}</b> X Challenge!</div>
            <div className={css.controls}>
              <Button href={`/challenges/${activeChallenge}`} target="blank" label="View Challenge"/>
            </div>
          </div>
        </Modal>
        */}

        <Modal ref={modal} onHide={continueSearching} isOpenInitial={true}>
          <div className={css.modalInner}>
            <div className={css.modalHeader}>X Marks the Site</div>
            <div className={css.modalSubheader}>The Scavenger Hunt Has Ended!</div>
            <div className={css.text}>
              <p>Thank you to everyone who participated and played our challenges. Stay tuned for an email announcing the winners in the coming weeks.</p>
              <p>Enjoy the infinite art....</p>
            </div>
            <div className={css.controls}>
              <Button onClick={() => {
                modal.current.hide();
              }} target="blank" label="View Art"/>
            </div>
          </div>
        </Modal>
        {/*disable signin wall
        <section className={`${css.signinWall} ${user?.value?.id ? css.hidden : ""}`}>
          <div className="sectionInner">
            <RegisterForm showLogo={false}/>
          </div>
        </section>
        */}

        <section className={css.infiniteArt}>
          <canvas className={css.pixiView} ref={pixiView}></canvas>
          {/*disable rules info icon on the left
          <Link href="/rules">
            <a target="_blank" className={`initialInvis ${css.infoTab}`}>
              <InfoSvg/>
            </a>
          </Link>
          */}

          <LoadingScreen ref={loadingScreen} progress={progress} errorMessage={errorMessage}/>
        </section>
      </main>
      <Footer/>
      <FloatingBar id="countdown" delay="20"/>
    </div>
  );
}

