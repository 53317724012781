import React, { useEffect, useState } from "react";
import Button from "@/components/button/index.jsx";
import css from "./index.module.scss";
import CancelSvg from "/public/images/icons/cancel.svg";
import {setCookie, getCookie } from "scripts/utils";
import gsap from "scripts/gsap";
import Link from "next/link";

export default function FloatingBar({
  id,
  delay = 0,
}) {
  let cookieName = `${id}FloatingBar`;

  function close(){
    let tlOut = gsap.timeline({});
    tlOut.fromTo(`.${css.floatingBar}`, {
      opacity: 1,
    }, {
      opacity: 0,
      duration: 0.5,
      pointerEvents: "none",
      ease: "sine.inOut",
      stagger: {
        each: 0.025,
        ease: "sine.in",
        from: "end",
      },
    });
    setCookie(cookieName, true, 1);
  }

  useEffect(() => {
    if (!getCookie(cookieName)){
      let tlIn = gsap.timeline({delay});
      tlIn.set(`.${css.floatingBar}`, {visibility: "visible"});
      tlIn.fromTo(`.${css.floatingBar}`, {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 0.5,
        ease: "sine.inOut",
        stagger: {
          each: 0.05,
          ease: "sine.in",
        },
      });

      return () => {
        tlIn.kill();
      };
    }
  }, [cookieName, delay]);


  if (id === "countdown"){
    return <div className={`${css.floatingBar}`}>
      <div className={`${css.background}`}></div>
      <div className={`sectionInner ${css.inner}`}>
        <div className={css.column}>
          <div className={`${css.headline}`}>
            <div>Now Available: <em>X</em> in Dolby Atmos</div>
          </div>
          <div className={`${css.subheadline}`}>
            <div>Download A Bad Think&apos;s new album &quot;X&quot; — produced by Michael Marquart, Dave Way, and Bob Clearmountain.</div>
          </div>
        </div>

        <div className={css.controls}>
          <div className={`${css.cta}`}>
            <Button className={`${css.ctaButton}`} target="_blank" href="https://immersiveaudioalbum.com/product/x-a-bad-think-atmos-mp4-mkv/" label="Buy Now"/>
            <Button className={`initialInvis ${css.ctaButton}`} target="_blank" href="/rules" label="Read Rules"/>
            <Button className={`initialInvis ${css.ctaButton}`} target="_blank" href="/rules" label="Read Rules"/>
          </div>
          <button onClick={close} className={`slidein cancel ${css.cancel}`}><CancelSvg/></button>
        </div>
      </div>
    </div>;
  }
}
