import gsap from "scripts/gsap";

export default function hideModal({isOpen, modalWrapper, css, setIsOpen}){
  return new Promise((resolve, reject) => {
    if (isOpen){
      setIsOpen(false);
      let tl = gsap.timeline({});
      tl.fromTo(modalWrapper.current.querySelector(`.${css.modal}`), {
        opacity: 1,
      }, {
        opacity: 0,
        ease: "sine.in",
        duration: 0.25,
        onComplete: resolve,
      }, "0");


      tl.to(modalWrapper.current.querySelectorAll(".blot"), {
        scaleX: 0,
        scaleY: 0,
        transformOrigin: "center center",
        duration: 0.3,
        rotate: "0deg",
        ease: "sine.in",
        stagger: {
          amount: 0.3,
          from: "random",
          ease: "sine.in",
        },
      }, "<");

      tl.to(modalWrapper.current.querySelector(".ink"), {
        opacity: 0,
        duration: 0.3,
        ease: "sine.in",
      }, "<50%");
      tl.set(modalWrapper.current, {visibility: "hidden"});
    }
  });
}
