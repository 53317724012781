import config from "/config.json";
import qs from "qs";
import {getCookie} from "/scripts/utils/index.js";

const endpoints = {
  USER_ME: {method: "GET", url: ({sort, populate, filters, data, auth, key}) => `${config.contentUrl}/api/users/me`},
  USER_GET: {method: "GET", url: (data) => `${config.contentUrl}/api/users/${data.auth.user.id}`},
  USER_PUT: {method: "PUT", url: (data) => `${config.contentUrl}/api/users/${data.data.id}`},
  USER_ME_PUT: {method: "PUT", url: (data) => `${config.contentUrl}/api/users-permissions/me`},


};

function query(endpoint, {
  sort = undefined,
  populate = undefined,
  filters = undefined,
  fields = undefined,
  data = {},
  auth = "",
  key = "",
}){
  //show draft content if this is a dev/staging site
  let publicationState = "live";
  if (process.env.ENV === "development" || process.env.ENV === "staging"){
    publicationState = "preview";
  }

  let url = endpoint.url({sort, populate, filters, data, auth, key});

  let settings = {};
  let jwt = auth?.jwt;

  if (!jwt){
    jwt = getCookie("jwt")?.replace(/"/g, "");
  }

  if (jwt || key){
    settings = {
      method: endpoint.method,
      credentials: "include",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key || jwt}`,
      },
    };
  }


  if (endpoint.method === "GET"){
    const queryString = qs.stringify({populate, sort, filters, publicationState}, {encodeValuesOnly: true});
    url += `?${queryString}`;
  }

  if (endpoint.method === "PUT"){
    settings.body = JSON.stringify(data);
  } else if (endpoint.method === "POST"){
    settings.body = JSON.stringify({data});
  }

  return fetch(url, settings)
    .then((res) => res.json())
    .then((res) => {
      if (res.error){
        console.error("Strapi error:", res.error);
      }
      if (res.data){
        return res.data;
      }
      return res;
    });
}

const ex = {
  endpoints,
  query,
};
export default ex;
